import cls from "classnames";
import axios from "axios";
import toast from "react-hot-toast";
import { forwardRef } from "react";

import { addTagsInRecording } from "@qoohoo/common/utils/analytics/clarity";
import { button } from "@qoohoo/common/constants/analytics"
import * as Track from "@qoohoo/common/utils/analytics/track";

import { creatorLoginUrl } from "constants/constants";
import useStore from "store/store";
import useSessionStore from "store/sessionStore";
  
  export const LoginButton = forwardRef(
    ({ currentScreen, isCreatorLogin = false, className, children, ...otherProps }, ref) => {
      const LoginModalController = useStore(
        (state) => state.LoginModalController
      );
  
      const handleLogin = () => {
        Track.buttonClick(button.login, {
          currentScreen,
        });
  
        if (!isCreatorLogin) {
          LoginModalController().openModal();
        } else {
          location.href = creatorLoginUrl;
        }
      };
  
      return (
        <button ref={ref} className={cls("appearance-none", className)} onClick={handleLogin} {...otherProps}>
          {children}
        </button>
      );
    }
  );
  
  export const LogoutButton = forwardRef(({ currentScreen, className, children, ...otherProps }, ref) => {
    const setLoaderState = useStore((state) => state.setLoaderState);
    const setUserData = useSessionStore((state) => state.setUserData);
  
    function handleLogout() {
      setLoaderState(true);
  
      axios.post("/api/auth/logout").finally(() => {
        setUserData({});
        setLoaderState(false);
  
        Track.buttonClick(button.logout, {
          currentScreen,
        });
  
        addTagsInRecording({ isUserLoggedOut: true });
  
        toast.success("Logged out successfully");
  
        location.reload();
      });
    }
  
    return (
      <button ref={ref} className={cls("appearance-none", className)} onClick={handleLogout} {...otherProps}>
        {children}
      </button>
    );
  });
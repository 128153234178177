export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

// TODO: Better DeepClone that supports non native data types
export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function mergeDeep(target, source, onlyMergeExisting=false) {
  let output = deepClone(target);

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if(!(key in target) && onlyMergeExisting) {
        return;
      };

      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: deepClone(source[key]) });
        else
          output[key] = mergeDeep(target[key], source[key], onlyMergeExisting);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export function breakArrayIntoSubArrays(originalArray, subArrayLength) {
  const res = [];
  for (let i = 0; i < originalArray.length; i += subArrayLength) {
    const subArray = originalArray.slice(i, i + subArrayLength);
    res.push(subArray);
  }
  return res;
}

export function partitionArray(array, isValid) {
  const res = [[],[]];
  
  array.forEach(elem=>{
    if(isValid(elem)) res[0].push(elem);
    else res[1].push(elem);
  });

  return res;
}

export function deepCopyNonSerializableObject(obj) {
  // check if obj is an object
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // create a copy of the object
  let copy = {};

  // loop through each property in the object
  for (let key in obj) {
    // check if the property is a function
    if (typeof obj[key] === 'function') {
      // bind the function to the copy object to preserve the context
      copy[key] = obj[key].bind(copy);
    } else {
      // recursively copy nested objects
      copy[key] = deepCopyNonSerializableObject(obj[key]);
    }
  }

  return copy;
}

export function deepCopy(obj, _self) {
  if (obj instanceof Date) {
    let copy = new Date();
    copy.setTime(obj.getTime());

    return copy;
  }

  if (typeof obj === 'function') {
    let copy;
    // bind the function to the copy object to preserve the context
    copy = obj.bind(_self);

    return copy;
  }


  if (obj instanceof Array) {
    let copy = [];

    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i], copy);
    }

    return copy;
  }

  if (obj instanceof Object) {
    let copy = {};

    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr], copy);
    }

    return copy;
  }

  return obj;

  // throw new Error("Unable to copy obj this object.");
}

export function isValidJSON(json) {
  let isValid = true;

  try {
    JSON.parse(json);
  } catch (err) {
    isValid = false;  
  }

  return isValid;
}
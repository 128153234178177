import cls from "classnames";
import { Menu, Transition } from "@headlessui/react";
import * as Track from "@qoohoo/common/utils/analytics/track";
import { useRef } from "react";
import Link from "next/link";

const FeaturesMenu = ({ current_screen, links, theme=FeaturesMenu.theme.dark, children }) => {
  const buttonRef = useRef(null);
  const dropDownRef = useRef(null);
  const timeoutDuration = 200;
  let timeout;

  const openMenu = () => {
    buttonRef.current.click();
  };

  const closeMenu = () => {
    dropDownRef.current?.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "Escape",
        bubbles: true,
        cancelable: true,
      })
    );
  };

  const onMouseEnter = (open) => {
    clearTimeout(timeout);
    !open && openMenu();
  };

  const onMouseLeave = (open) => {
    if (open) {
      timeout = setTimeout(closeMenu, timeoutDuration);
    }
  };

  return (
    <Menu as="div" className="z-10 w-full relative">
      {({ open }) => (
        <>
          <div onMouseEnter={()=>onMouseEnter(open)} onMouseLeave={()=>onMouseLeave(open)} onClick={openMenu}>
            <Menu.Button ref={buttonRef} className={cls("relative px-4 h-9 btn-hover-bg cursor-pointer rounded-sm", theme.hoverClass)}>
              {children}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
          >
            <Menu.Items ref={dropDownRef} static onMouseEnter={()=>onMouseEnter(open)} onMouseLeave={()=>onMouseLeave(open)} className="absolute outline-none">
              <div 
                className="flex flex-col mt-4 w-[fit-content] min-w-[200px] rounded-xl text-base overflow-hidden"
                style={{
                  color: theme.primaryColor,
                  background: theme.backgroundColor,
                  border: `1px solid ${theme.borderColor}`
                }}
              >
              {links.map((linkItem)=>(
                <Menu.Item key={linkItem.label}>
                {({ active }) => (
                  <Link
                    href={linkItem.link}
                    target="_blank"
                    rel="noopener"
                    className="relative whitespace-nowrap p-4"
                    style={{
                      background: active && theme.buttonHoverColor
                    }}
                    onClick={() => {
                      Track.linkClick(linkItem.key, {
                        url: linkItem.link,
                        current_screen,
                      });
                    }}
                  >
                    {linkItem.label}
                  </Link>
                )}
              </Menu.Item>
              ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default FeaturesMenu;

FeaturesMenu.theme = {
  light: {
    primaryColor: "#16191b",
    backgroundColor: "#ffffff",
    buttonHoverColor: "rgba(0,0,0,0.05)",
    borderColor: "#E5E5E6",
    hoverClass: "btn-hover-bg-light"
  }, 
  dark: {
    primaryColor: "#E5E5E6",
    backgroundColor: "#1D2225",
    buttonHoverColor: "rgba(255,255,255,0.05)",
    borderColor: "transparent",
    hoverClass: ""
  }
}
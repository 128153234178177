import cls from "classnames";
import { Menu } from "@headlessui/react";

import { button, link } from "@qoohoo/common/constants/analytics";

import { creatorOnboardingUrl } from "constants/constants";
import useSessionStore from "store/sessionStore";
import HamMenuLayout from "./HamMenuLayout";
import { LoginButton, LogoutButton } from "components/auth/AuthButtons";

const allNavItems = [
  {
    title: "COMPANY",
    links: [
      {
        key: link.about,
        label: "About",
        link: "/about",
        trackType: "link",
      },
      {
        key: button.become_creator,
        label: "Become a creator",
        link: creatorOnboardingUrl,
        trackType: "button",
      },
    ],
  },
  {
    title: "Features",
    links: [
      {
        key: link.telegram_payments_product_page,
        label: "Telegram Payments",
        link: `/telegram-payments`,
        trackType: "link",
      },
      {
        key: link.whatsapp_payments_product_page,
        label: "WhatsApp Payments",
        link: `/whatsapp-payments`,
        trackType: "link",
      },
      {
        key: link.monetise_youtube_videos_product_page,
        label: "Monetise YouTube",
        link: `/monetise-youtube-videos`,
        trackType: "link",
      },
    ],
  },
  {
    title: "MORE ON qoohoo",
    links: [
      {
        key: link.blog,
        label: "Blog",
        link: "/blog",
        trackType: "link",
      },
      {
        key: link.creators_guide,
        label: "Creator Guide",
        link: "/creators-guide",
        trackType: "link",
      },
    ],
  },
  {
    title: "HELP & GUIDE",
    links: [
      {
        key: link.support,
        label: "24x7 Support",
        link: "/support",
        trackType: "link",
      },
      {
        key: link.faq,
        label: "FAQs",
        link: "/faq",
        trackType: "link",
      },
    ],
  },
];

export function HamAuthComponent({
  currentScreen,
  className,
  theme = HamAuthComponent.theme.dark,
}) {
  const userData = useSessionStore((state) => state.userData);
  const isLoggedIn = !!userData?.uid;

  const buttonStyle = {
    background: theme.buttonBackground,
    color: theme.primaryColor,
    border: `1px solid ${theme.buttonBorderColor}`,
  };

  const containerStyle = {
    borderTop: `1px solid ${theme.borderColor}`,
  };

  return (
    <div className={cls("p-4", className)} style={containerStyle}>
      <Menu.Item as="div" className="flex items-center justify-center">
        {isLoggedIn ? (
          <LogoutButton
            className={cls("px-5 h-10 text-sm font-bold rounded-full hover:brightness-90", theme.buttonClass)}
            currentScreen={currentScreen}
            style={buttonStyle}
          >
            Logout
          </LogoutButton>
        ) : (
          <LoginButton
            className={cls("px-5 h-10 text-sm font-bold rounded-full hover:brightness-90", theme.buttonClass)}
            currentScreen={currentScreen}
            style={buttonStyle}
            isCreatorLogin={true}
          >
            Login
          </LoginButton>
        )}
      </Menu.Item>
    </div>
  );
}

HamAuthComponent.theme = {
  light: {
    borderColor: "#eff0f1",
    primaryColor: "#16191B",
    buttonBorderColor: "transparent",
    buttonBackground: "rgba(0,0,0,0.07)",
    buttonClass: "",
  },
  dark: {
    borderColor: "#D3D5D71A",
    primaryColor: "#ffffff",
    buttonBorderColor: "transparent",
    buttonBackground: "rgba(255,255,255,0.07)",
    buttonClass: "",
  },
};

export default function HamMenuMain({
  currentScreen,
  showAuth = true,
  theme = HamMenuMain.theme.dark,
  navItems = allNavItems,
}) {
  const hamIcon = () => (
    <img className="select-none" src={theme.hamIcon} alt="Menu button icon" />
  );

  const hamAuthTheme = theme.auth;

  return (
    <HamMenuLayout
      navItems={navItems}
      currentScreen={currentScreen}
      hamIcon={hamIcon}
      theme={theme}
      hamAuthComponent={(props) => (
        showAuth ? <HamAuthComponent theme={hamAuthTheme} {...props} /> : null
      )}
    />
  );
}

HamMenuMain.theme = {
  light: {
    primaryColor: "#16191B",
    background: "#ffffff",
    borderColor: "#D3D5D7",
    hamIcon: "/assets/icons/menu-dark.svg",
    auth: HamAuthComponent.theme.light,
  },
  dark: {
    primaryColor: "#ffffff",
    background: "#272B30",
    borderColor: "#292E33",
    hamIcon: "/assets/icons/menu.svg",
    auth: HamAuthComponent.theme.dark,
  },
};
import cls from "classnames";
import Link from "next/link";

import * as Track from "../../utils/analytics/track";

import LinksDropDown from "../LinksDropDown";
import { useEffect, useState } from "react";

export default function NavLayout({
  className,
  style,
  theme,
  currentScreen,
  logoComponent,
  navItems,
  authNavComponent,
  hamMenuComponent,
  extraComponent,
  isSticky,
}) {
  const [showStickyNav, setShowStickyNav] = useState(false);

  useEffect(() => {
    let lastScrollValue;

    const scrollHandler = () => {
      const mainNav = document.getElementsByClassName("main-nav")[0];

      if (mainNav) {
        const scrollBarEnd = mainNav.getBoundingClientRect().bottom;

        // Note: Enable and add check if you want navbar to hide on scrolldown and visible on scrollup
        // const isScrollDirectionUp =
        //   lastScrollValue && lastScrollValue > window.scrollY;
        const isNormalScrollbarHidden = scrollBarEnd < 0;

        if (isNormalScrollbarHidden) {
          setShowStickyNav(true);
        } else {
          setShowStickyNav(false);
        }

        lastScrollValue = window.scrollY;
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  function trackFn(type, name, data = {}) {
    let _trackFn;

    if (Track && type) {
      if (type === "link") {
        _trackFn = Track.buttonClick;
      } else if (type === "button") {
        _trackFn = Track.linkClick;
      }

      _trackFn && _trackFn(name, data);
    }
  }

  function renderNavItems() {
    const content = navItems.map((item, idx) => {
      let content;

      const track = {
        type: item.trackType,
        name: item.key,
        data: {
          current_screen: currentScreen,
        },
      };

      if (item.link) {
        track.data.url = item.link;

        content = (
          <Link
            key={idx}
            className={cls("relative flex items-center px-4 h-9 text-sm md:text-base", theme.linkHoverClass)}
            href={item.link}
            rel="noopener"
            target={item.target || "_blank"}
            onClick={() => trackFn(track.type, track.name, track.data)}
          >
            {item.label}
          </Link>
        );
      } else if (item.dropdown) {
        content = (
          <div key={idx} className="relative">
            <LinksDropDown
              currentScreen={currentScreen}
              links={item.dropdown}
              onClick={() => trackFn(track.type, track.name, track.data)}
              theme={theme.dropdown}
            >
              {item.label}
            </LinksDropDown>
          </div>
        );
      }

      return content;
    });

    return <div className="flex">{content}</div>;
  }

  let navbar = (
    <header
      className={cls(
        "w-full relative flex items-center h-[4.5rem] font-nunito z-10",
        "px-5 sm:px-8 md:px-16 lg:px-20 py-3",
        "main-nav",
        className
      )}
      style={{
        color: theme.primaryColor,
        background: theme.background,
        ...style,
      }}
    >
      <nav className="w-full relative">
        <div className="flex items-center justify-between">
          {logoComponent ? logoComponent() : null}

          <div className="flex items-center min-w-0">
            {/* Show only on non-mobile mode */}
            <div className="hidden md:flex items-center">
              {navItems?.length > 0 ? renderNavItems() : null}
            </div>

            {authNavComponent ? authNavComponent({ currentScreen }) : null}

            {/* Show only on mobile mode */}
            <div className="flex items-center md:hidden">
              {hamMenuComponent ? hamMenuComponent({ currentScreen }) : null}
            </div>
            {/* Ad-hoc components */}
            {extraComponent ? extraComponent({currentScreen}) : null}
          </div>
        </div>
      </nav>
    </header>
  );

  let replicaNavbar = isSticky ? (
    <div
      className={cls(
        "fixed left-0 right-0 top-0 z-10 transition-transform",
        showStickyNav ? "translate-y-0" : "-translate-y-full"
      )}
    >
      <div
        className={cls(
          "absolute inset-0 overflow-hidden bg-[#16191B] bg-opacity-90 backdrop-blur-sm"
        )}
      ></div>
      {navbar}
    </div>
  ) : null;

  return (
    <>
      {navbar}

      {replicaNavbar}
    </>
  );
}

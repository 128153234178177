import cls from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Link from "next/link";

import * as Track from "@qoohoo/common/utils/analytics/track";

function HamMenuLayout({
  hamIcon,
  hamAuthComponent,
  extraComponent,
  navItems,
  currentScreen,
  className,
  style,
  theme,
}) {
  function trackFn(type, name, data = {}) {
    let _trackFn;

    if (Track && type) {
      if (type === "link") {
        _trackFn = Track.buttonClick;
      } else if (type === "button") {
        _trackFn = Track.linkClick;
      }

      _trackFn && _trackFn(name, data);
    }
  }

  function renderNavItems() {
    const content = navItems.map((item, idx) => {
      return (
        <div key={idx} className="mb-6">
          {item?.title && (
            <div className="font-bold select-none">{item.title}</div>
          )}
          {item.links?.length > 0 && (
            <div className="flex flex-col">
              {item.links.map((linkItem, idx) => {
                const track = {
                  type: linkItem.trackType,
                  name: item.key,
                  data: {
                    url: linkItem.link,
                    current_screen: currentScreen,
                  },
                };

                return (
                  <Menu.Item key={idx} as="div" className="mt-3">
                    <Link
                      href={linkItem.link}
                      target={linkItem.target || "_blank"}
                      rel="noopener"
                      className="brightness-75 hover:brightness-90"
                      onClick={() =>
                        trackFn(track.type, track.name, track.data)
                      }
                    >
                      {linkItem.label}
                    </Link>
                  </Menu.Item>
                );
              })}
            </div>
          )}
        </div>
      );
    });

    return content;
  }

  function renderWithLove() {
    return (
      <div className="mt-6">
        <span
          className={cls("text-[13px] sm:text-base select-none")}
        >
          Made with
          <img
            src="/assets/icons/heart.svg"
            alt="love"
            className="mx-1.5 inline h-3 w-3 select-none"
          />
          in India
        </span>
      </div>
    );
  }

  return (
    <Menu
      as="div"
      className={cls(
        "inline-block w-full ml-2 text-left font-nunito z-10",
        className
      )}
    >
      <div>
        <Menu.Button
          className={cls(
            "inline-flex justify-center w-full px-2 py-[0.625rem] text-sm font-medium rounded-full bg-opacity-10 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-40",
          )}
        >
          {hamIcon ? hamIcon() : null}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="absolute top-0 right-0 w-full sm:max-w-[380px] p-2 z-10">
            <Menu.Items
              as="div"
              className={cls(
                "rounded-lg overflow-hidden shadow-lg",
              )}
              style={{
                color: theme.primaryColor,
                background: theme.background,
                border: `1px solid ${theme.borderColor}`,
                ...style,
              }}
            >
              <Menu.Item
                as="div"
                className="absolute text-4xl rotate-45 cursor-pointer right-8 top-5"
              >
                +
              </Menu.Item>

              <div className="p-6 text-sm">
                {navItems?.length > 0 ? renderNavItems() : null}

                {extraComponent ? extraComponent({currentScreen}) : null}

                {renderWithLove()}
              </div>

              {hamAuthComponent ? hamAuthComponent({ currentScreen }) : null}
            </Menu.Items>
          </div>
        </Transition>
      </div>
    </Menu>
  );
}

export default HamMenuLayout;
